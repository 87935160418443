/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { API, Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Stack, Flex, Text, Heading, Skeleton } from '@chakra-ui/react';
import { ReportProps } from '../../typings';

const BasicReportingSection = ({
  id,
  setLoading,
  loading,
}: ReportProps): JSX.Element => {
  const [totalAssets, setTotalAssets] = React.useState(0);
  const [totalContacts, setTotalContacts] = React.useState(0);
  const [loadedAssets, setLoadedAssets] = React.useState(false);
  const [loadedContacts, setLoadedContacts] = React.useState(false);
  const { currentAccount } = useSelector((state: any) => state.account);

  const getAccountContactsCount = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get(
        'openscreen',
        `accounts/${currentAccount?.accountId}/contacts/report/basic `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalContacts(res.contactsCount);
      setLoadedContacts(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountAssetsCount = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get(
        'openscreen',
        `accounts/${currentAccount?.accountId}/assets/report/basic `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalAssets(res.assetsCount);
      setLoadedAssets(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectContactsCount = async (projectId: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get(
        'openscreen',
        `projects/${projectId}/contacts/report/basic `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalContacts(res.contactsCount);
      setLoadedContacts(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectAssetsCount = async (projectId: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await API.get(
        'openscreen',
        `projects/${projectId}/assets/report/basic `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalAssets(res.assetsCount);
      setLoadedAssets(true);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setLoadedContacts(false);
    setLoadedAssets(false);
    if (id.toLowerCase() === 'account') {
      // GET Report by accountId
      getAccountAssetsCount();
      getAccountContactsCount();
    } else if (id) {
      // GET Report by projectId
      getProjectAssetsCount(id);
      getProjectContactsCount(id);
    }
  }, [id, currentAccount?.accountId]);

  React.useEffect(() => {
    setLoading(loadedContacts && loadedAssets);
  }, [loadedAssets, loadedContacts]);

  return (
    <Skeleton padding="20px" bgColor="#fff" width="100%" isLoaded={loading}>
      <Flex marginX="20px" direction="column">
        <Stack spacing={7}>
          <Heading fontSize="18pt">Basic Report</Heading>
          <Stack margin="20px">
            <Flex justify="space-between">
              <Text>Total Assets Stored</Text>
              <Text>{totalAssets}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text>Total Contacts Stored</Text>
              <Text>{totalContacts}</Text>
            </Flex>
          </Stack>
        </Stack>
      </Flex>
    </Skeleton>
  );
};

export default BasicReportingSection;
