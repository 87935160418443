import * as React from 'react';
import {
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  Box,
  Text,
  Link,
  Skeleton,
  Flex,
} from '@chakra-ui/react';
import { Auth, API } from 'aws-amplify';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { useSelector } from 'react-redux';
import 'react-google-flight-datepicker/dist/main.css';
import { ReportProps } from '../../typings';

const DataExportSection = ({
  id,
  loading,
  viewable,
}: ReportProps): JSX.Element => {
  const initStartDate = new Date();
  initStartDate.setDate(initStartDate.getDate() - 7);

  const [value, setValue] = React.useState('1');
  const { currentAccount } = useSelector((state: any) => state.account);
  const [dateRange, setDateRange] = React.useState([initStartDate, new Date()]);

  const onDateChange = (startDate: Date, endDate: Date) => {
    setDateRange([startDate, endDate]);
  };

  const onSubmit = async () => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      const newStartDate = dateRange[0].toISOString().split('T')[0];
      const newEndDate = dateRange[1].toISOString().split('T')[0];
      let headers;
      if (value === 'csv') {
        headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/CSV',
        };
      } else {
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      if (id.toLowerCase() === 'account') {
        await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/scans/export?format=${value}&from=${newStartDate}&to=${newEndDate}`,
          {
            headers,
          }
        );
      } else {
        await API.get(
          'openscreen',
          `projects/${id}/scans/export?format=${value}&from=${newStartDate}&to=${newEndDate}`,
          {
            headers,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Skeleton bgColor="#fff" isLoaded={loading} width="100%">
      <Stack paddingY="25px" marginX="25px">
        <HStack marginY="5px">
          <Heading fontSize="18pt">Data Export {viewable}</Heading>
          <Link
            href="/app/account/subscription-plan"
            display={viewable ? 'none' : 'initial'}
          >
            (Upgrade)
          </Link>
        </HStack>
        {/* blur based on account price plan */}
        <Box display={viewable ? 'initial' : 'none'}>
          <Stack>
            <RangeDatePicker
              onChange={onDateChange}
              startDate={dateRange[0]}
              endDate={dateRange[1] || new Date(2020, 0, 15)}
              startDatePlaceholder="From"
              endDatePlaceholder="To"
            />
            {/* DOWNLOAD BUTTON */}
            <RadioGroup onChange={setValue}>
              <Radio
                marginRight="10px"
                size="md"
                value="csv"
                colorScheme="green"
              >
                CSV
              </Radio>
              <Radio size="md" value="json" colorScheme="green">
                JSON
              </Radio>
            </RadioGroup>
            <Button
              onClick={onSubmit}
              borderRadius="55px"
              paddingX="10px"
              paddingY="25px"
              bgColor="#4fc1ff"
              color="#fff"
              _hover={{
                bgColor: '#000',
              }}
            >
              Download
            </Button>
          </Stack>
        </Box>
        <Flex display={viewable ? 'none' : 'initial'}>
          <Text>
            You do not have access to plan, please upgrade to gain access.
          </Text>
        </Flex>
      </Stack>
    </Skeleton>
  );
};

export default DataExportSection;
