/* eslint-disable react-hooks/exhaustive-deps */
import {
  Heading,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';

import * as React from 'react';
import { ReportProps } from '../../typings';
import AssetsTable from './assetsTable/assetsTable';
import ContactsTable from './contactsTable/contactsTable';

const AdvancedReportingSection = ({
  id,
  loading,
  setLoading,
  viewable,
}: ReportProps): JSX.Element => {
  const [option, setOption] = React.useState('assets');

  return (
    <Skeleton
      marginX="20px"
      padding="20px"
      isLoaded={loading}
      marginLeft="30px"
      height="100%"
      bgColor="#fff"
    >
      <Stack height="100%" maxW="600px">
        <HStack>
          <Heading fontSize="18pt">Advanced Report</Heading>
          <Link
            href="/app/account/subscription-plan"
            display={viewable ? 'none' : 'initial'}
          >
            (Upgrade)
          </Link>
        </HStack>
        <Flex
          width="100%"
          marginY="25px"
          justify="center"
          align="center"
          display={viewable ? 'none' : 'initial'}
        >
          <Text>
            You do not have access to plan, please upgrade to gain access.
          </Text>
        </Flex>
      </Stack>
      <Box display={!viewable ? 'none' : 'initial'}>
        <RadioGroup value={option} onChange={setOption} defaultValue="contacts">
          <HStack marginLeft="15px" marginY="15px" spacing={2}>
            <Radio value="contacts" size="md" color="#41c1ff">
              Contacts
            </Radio>
            <Radio value="assets" size="md" color="#41c1ff">
              Assets
            </Radio>
          </HStack>
        </RadioGroup>
        <Box display={option === 'assets' ? 'block' : 'none'}>
          <AssetsTable id={id} loading={loading} setLoading={setLoading} />
        </Box>
        <Box display={option === 'contacts' ? 'block' : 'none'}>
          <ContactsTable id={id} loading={loading} setLoading={setLoading} />
        </Box>
      </Box>
    </Skeleton>
  );
};

export default AdvancedReportingSection;
