export interface ReportProps {
  id?: string;
  setLoading?: any;
  loading?: boolean;
  viewable?: boolean;
}

export interface DataExportProps {
  startDate: Date;
  id?: string;
  endDate: Date;
}

export interface Meta {
  total?: number;
  size?: number;
  queryExecutionId?: string;
  nextToken?: string;
}
export interface response {
  meta?: Meta;
  data?: Array<any>;
}

export interface PageToken {
  queryExecutionId: string;
  encodedNextToken: string;
}

export interface ChartProps {
  labels?: Array<string>;
  data?: Array<number>;
}

export const outerLimit = 2;
export const innerLimit = 2;
