import { Box, Heading, Flex, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import ReportingLevel from '../../menus/reporting/reportingLevel';
import AdvancedReportingSection from './advanced-reporting/advanced-reporting';
import BasicReportingSection from './basic-reporting/basic-reportings';
import DataExportSection from './data-export/data-export';

const AssetContactData = (): JSX.Element => {
  const [id, setID] = React.useState('account');
  const [loadedBasic, setLoadedBasic] = React.useState(false);
  const [loadedAdvanced, setLoadedAdvanced] = React.useState(false);
  const { currentAccount } = useSelector((state: any) => state.account);

  const handleBasicLoadChange = React.useCallback((newState: boolean) => {
    setLoadedBasic(newState);
  }, []);

  const handleAdvancedLoadChange = React.useCallback((newState: boolean) => {
    setLoadedAdvanced(newState);
  }, []);

  const handleLoading = (val: boolean) => {
    setLoadedAdvanced(val);
    setLoadedBasic(val);
  };

  return (
    <Box maxW="70vw" height="100%" marginX="40px">
      <Flex align="start" justify="start" margin="none">
        <Stack spacing="25px" w="50%" align="start" justify="start">
          <Flex
            bgColor="#fff"
            justify="start"
            align="start"
            w="21vw"
            minW="300px"
            padding="20px"
            h="137px"
            width="100%"
            direction="column"
          >
            <Heading marginBottom="15px" fontWeight="bold" fontSize="26pt">
              Asset Contact Data
            </Heading>
            <ReportingLevel setLoaded={handleLoading} setProjectId={setID} />
          </Flex>
          <BasicReportingSection
            setLoading={handleBasicLoadChange}
            loading={loadedAdvanced && loadedBasic}
            id={id}
          />
          <Stack w="100%">
            <DataExportSection
              viewable={currentAccount?.pricePlan?.dataExport}
              loading={loadedAdvanced && loadedBasic}
              id={id}
            />
          </Stack>
        </Stack>
        <Box width="100%" height="100%">
          <AdvancedReportingSection
            viewable={currentAccount?.pricePlan.reporting !== 'basic'}
            setLoading={handleAdvancedLoadChange}
            loading={loadedAdvanced && loadedBasic}
            id={id}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default AssetContactData;
