/* eslint-disable react-hooks/exhaustive-deps */
import {
  usePagination,
  Pagination,
  PaginationContainer,
  PaginationPrevious,
  PaginationNext,
} from '@ajna/pagination';
import { Auth, API } from 'aws-amplify';
import * as React from 'react';
import {
  Skeleton,
  Stack,
  Thead,
  Tr,
  Table,
  Th,
  Tbody,
  Td,
  Text,
  Flex,
  Button,
  Input,
  HStack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  outerLimit,
  response,
  innerLimit,
  ReportProps,
  PageToken,
} from '../../../typings';

const AssetsTable = ({ id, setLoading }: ReportProps): JSX.Element => {
  const { register, handleSubmit } = useForm<{ search: string }>();

  const { currentAccount } = useSelector((state: any) => state.account);
  const [loaded, setLoaded] = React.useState(false);
  const [assets, setAssets] = React.useState<response>({ meta: {}, data: [] });
  const [assetsTotal, setAssetsTotal] = React.useState<number | undefined>(5);
  const [pageTokens, setPageTokens] = React.useState<Array<PageToken>>([
    { queryExecutionId: '', encodedNextToken: '' },
  ]);

  const { pagesCount, currentPage, setCurrentPage } = usePagination({
    total: assetsTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 5,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const getNextPage = async () => {
    setLoaded(false);
    const encodedNextToken = encodeURIComponent(assets.meta.nextToken);
    const { queryExecutionId } = assets.meta;

    let res;
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      if (id.toLowerCase() === 'account') {
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/assets/report/advanced?limit=5&queryExecutionId=${queryExecutionId}&nextToken=${encodedNextToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `projects/${id}/assets/report/advanced?limit=5&queryExecutionId=${queryExecutionId}&nextToken=${encodedNextToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      setAssets(res);
      setLoaded(true);
      const newList = pageTokens;
      newList.push({ queryExecutionId, encodedNextToken });
      setPageTokens(newList);
    } catch (error) {
      setLoaded(true);
      console.error(error);
    }
  };

  const getInitElements = async () => {
    setLoaded(false);
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let res;
      if (id.toLowerCase() === 'account') {
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/assets/report/advanced?limit=5`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `projects/${id}/assets/report/advanced?limit=5`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setAssets(res);
      setAssetsTotal(res.meta.total);
      setLoading(true);
      setLoaded(true);
    } catch (error) {
      setLoading(true);
      setLoaded(true);

      console.log(error);
    }
  };

  const filter = async (search: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let res;
      if (id.toLowerCase() === 'account') {
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/assets/report/advanced?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `projects/${id}/assets/report/advanced?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setAssets(res);
      setAssetsTotal(res.meta.total);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      if (error.status === 400) {
        console.log(400);
      }
      console.log(error);
    }
  };

  const getPreviousAssetPage = async (pageNumber: number) => {
    setLoaded(false);
    const pageToken = pageTokens[pageNumber - 1];
    let res;
    if (
      pageToken.queryExecutionId === 'none' ||
      pageToken.encodedNextToken === 'none'
    ) {
      getInitElements();
    } else {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      if (id.toLowerCase() === 'account') {
        res = await API.get(
          'openscreen',
          `accounts/${currentAccount?.accountId}/assets/report/advanced?limit=5&queryExecutionId=${pageToken.queryExecutionId}&nextToken=${pageToken.encodedNextToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        res = await API.get(
          'openscreen',
          `projects/${id}/assets/report/advanced?limit=5&queryExecutionId=${pageToken.queryExecutionId}&nextToken=${pageToken.encodedNextToken}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      setAssets(res);
      setLoaded(true);
    }
  };

  const handlePageChange = (nextPage: number): void => {
    if (currentPage > nextPage) {
      getPreviousAssetPage(nextPage);
    } else {
      getNextPage();
    }
    setCurrentPage(nextPage);
  };

  const onSearch = (data: { search: string }) => {
    setLoaded(false);
    filter(data.search);
  };

  React.useEffect(() => {
    getInitElements();
  }, [id, currentAccount?.accountId]);

  return (
    <>
      <form action="submit" onSubmit={handleSubmit(onSearch)}>
        <HStack marginLeft="15px" marginY="15px">
          <Input
            {...register('search', { required: true })}
            placeholder="Asset Name"
          />
          <Button
            bgColor="#4fc1ff"
            maxW="450px"
            borderRadius="25px"
            color="#fff"
            isLoading={!loaded}
            _hover={{
              bgColor: '#000',
            }}
            type="submit"
          >
            Search
          </Button>
        </HStack>
      </form>
      <Skeleton isLoaded={loaded}>
        <Stack>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Asset Name</Th>
                <Th>Openscreen Asset ID</Th>
                <Th>Scans</Th>
              </Tr>
            </Thead>
            <Tbody>
              {assets.data ? (
                assets?.data.map((e) => {
                  return (
                    <Tr key={e?.assetId}>
                      <Td>{e?.name}</Td>
                      <Td>{e?.assetId}</Td>
                      <Td>{e?.totalScans}</Td>
                    </Tr>
                  );
                })
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
          {assets.data.length === 0 ? (
            <Flex width="100%" padding="30px" align="center" justify="center">
              <Text>No data found</Text>
            </Flex>
          ) : (
            <></>
          )}
        </Stack>
      </Skeleton>

      <Stack marginY="20px" justify="center" align="center">
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        >
          <PaginationContainer>
            <PaginationPrevious colorScheme="blackAlpha" mr={2}>
              Previous
            </PaginationPrevious>
            <PaginationNext colorScheme="blackAlpha" ml={2}>
              Next
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </Stack>
    </>
  );
};

export default AssetsTable;
